@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/*Esconder o Ok e Cancelar do datapicker*/
.css-hlj6pa-MuiDialogActions-root{
  display: none !important;
}

/*Data picker style*/
.css-svtboj-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #6870fa !important;
}

.css-gk0ydk-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #6870fa !important;
}

.css-11g5a6-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #6870fa !important;
}

.css-1hp081c-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #6870fa !important;
}

.css-u0soqy-MuiPickerStaticWrapper-root{
  background-color: #1F2A40 !important;

}

/*).css-gk0ydk-MuiButtonBase-root-MuiPickersDay-root{
  font-size: 20px !important;
}*/
/*

.css-1tbstpx-MuiTypography-root-MuiDayPicker-weekDayLabel{
  font-size: 16px !important;  
}
.css-gk0ydk-MuiButtonBase-root-MuiPickersDay-root{
  font-size: 16px !important;  
}
.css-dplwbx-MuiPickersCalendarHeader-label{
  font-size: 16px !important;  
}
.css-11g5a6-MuiButtonBase-root-MuiPickersDay-root{
  background-color: #e0e0e0 !important;
}
.css-11g5a6-MuiButtonBase-root-MuiPickersDay-root:hover{
  background-color: #a3a3a3 !important;
}
*/